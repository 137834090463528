import deepmerge from 'deepmerge';

import { styled } from '@sparky/stitches';

import { campaignBackgroundSpeechBubble, getCampaignBorders } from './campaignTokens';

const speechBubbleBorderLeft = getCampaignBorders('borderLeft', 'backgroundSpeechBubble');

export const SpeechBubble = styled('div', {
  position: 'relative',
  width: '100%',
  wordBreak: 'break-word',
  padding: '$space$5',
  zIndex: '1',

  '&::after': {
    content: '',
    display: 'block',
    position: 'absolute',
    width: '$space$10',
    height: '$space$12',
    left: '0',
    bottom: '-$space$10',
    borderRight: '0px solid transparent',
    borderBottom: '48px solid transparent',
  },

  variants: {
    backgroundColor: deepmerge(campaignBackgroundSpeechBubble, speechBubbleBorderLeft),
  },
});

export const SpeechBubbleBackground = styled('div', {
  padding: '$5',
  variants: {
    backgroundColor: campaignBackgroundSpeechBubble,
  },
});
