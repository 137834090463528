import { Heading, Text } from '@sparky';
import { styled } from '@sparky/stitches';

import { campaignBackgroundColors, campaignTextColors, campaignTypography } from './campaignTokens';
import { SpeechBubble } from './SpeechBubble';

export const CardContainer = styled('div', {
  position: 'relative',
});

export const HeadingSpan = styled('span', {
  color: '$borderSelected',
});

export const CardSpeechBubble = styled(SpeechBubble, {
  marginTop: '-$space$16',
  left: '0',

  '@md': {
    marginBottom: '$space$32',
    padding: '$space$10',
  },
  '@lg': {
    bottom: '0',
    marginTop: 'unset',
  },

  variants: {
    language: {
      nl: {
        marginBottom: '112px',
        '@lg': {
          marginBottom: '118px',
        },
      },
      en: {
        marginBottom: '112px',
        '@lg': {
          marginBottom: '118px',
        },
      },
      fr: {
        marginBottom: '140px',
        '@lg': {
          marginBottom: '140px',
        },
      },
    },
  },
  defaultVariants: {
    language: 'nl',
  },
});

export const CardSpeechBubbleHeading = styled(Heading, {
  textTransform: 'uppercase',
  overflowWrap: 'break-word',
  variants: {
    hyphens: {
      manual: {
        hyphens: 'manual',
      },
      auto: {
        hyphens: 'auto',
      },
    },
    size: {
      headingM: campaignTypography.headingM,
      headingL: campaignTypography.headingL,
      heading2xl: campaignTypography.heading2xl,
    },
  },
  defaultVariants: {
    size: 'headingM',
    hyphens: 'auto',
  },
});

export const CardSpeechBubbleText = styled('span', {
  display: 'block',
  maxWidth: '417px',
});

export const StyledText = styled(Text, {
  variants: {
    color: campaignTextColors,
  },
});

export const CardBackground = styled('div', {
  height: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  padding: '$space$6',

  '@md': {
    padding: '$space$10',
    paddingBottom: '$space$10',
  },

  '@lg': {
    marginRight: '$space$8',
    padding: '$space$8',
  },

  variants: {
    backgroundColor: campaignBackgroundColors,
    language: {
      nl: {
        '@lg': {
          minHeight: '300px',
        },
      },
      en: {
        '@lg': {
          minHeight: '300px',
        },
      },
      fr: {
        '@lg': {
          minHeight: '340px',
        },
      },
    },
  },
  defaultVariants: {
    language: 'nl',
  },
});

export const PayOffWrapper = styled('div', {
  variants: {
    language: {
      nl: {
        height: '20px',
        width: '240px',
      },
      en: {
        height: '24px',
        width: '240px',
      },
      fr: {
        height: '53px',
        width: '240px',
      },
    },
  },
  defaultVariants: {
    language: 'nl',
  },
});
