import type { CampaignColorTokens } from '@sitecore/types/manual/WeDoenHetNuTestimonialsCard';

// Campaign Colors
type CampaignColors = Record<
  CampaignColorTokens,
  { backgroundColor: string; backgroundSpeechBubble: string; textColor: string }
>;

export const campaignColors: CampaignColors = {
  weDoenHetNuVarOne: {
    backgroundColor: '#09354B',
    backgroundSpeechBubble: '#72BDCE',
    textColor: '#09354B',
  },
  weDoenHetNuVarTwo: {
    backgroundColor: '#0A4033',
    backgroundSpeechBubble: '#7EC389',
    textColor: '#0A4033',
  },
  weDoenHetNuVarThree: {
    backgroundColor: '#3E235B',
    backgroundSpeechBubble: '#8D8CC6',
    textColor: '#3E235B',
  },
  weDoenHetNuVarFour: {
    backgroundColor: '#552748',
    backgroundSpeechBubble: '#EC6C87',
    textColor: '#552748',
  },
};

export const getCampaignColors = (prop: string, type: 'backgroundColor' | 'backgroundSpeechBubble' | 'textColor') => {
  const result: { [variableName: string]: { [propName: string]: string } } = {};
  for (const variable of Object.keys(campaignColors)) {
    result[variable as CampaignColorTokens] = { [prop]: campaignColors[variable as CampaignColorTokens][type] };
  }
  return result;
};

export const getCampaignBorders = (prop: string, type: 'backgroundColor' | 'backgroundSpeechBubble' | 'textColor') => {
  const result: {
    [variableName: string]: {
      [propName: string]: {
        [x: string]: string;
      };
    };
  } = {};
  for (const variable of Object.keys(campaignColors)) {
    result[variable as CampaignColorTokens] = {
      '&::after': {
        [prop]: `40px solid ${campaignColors[variable as CampaignColorTokens][type]}`,
      },
    };
  }
  return result;
};

export const campaignBackgroundColors = getCampaignColors('backgroundColor', 'backgroundColor');
export const campaignTextColors = getCampaignColors('color', 'textColor');
export const campaignBackgroundSpeechBubble = getCampaignColors('backgroundColor', 'backgroundSpeechBubble');

// Campaign Typography
export const campaignTypography = {
  heading2xl: {
    letterSpacing: '$narrowM',
    fontSize: '$2XL',
    fontWeight: 900,
    lineHeight: '5.6rem',
  },
  headingL: {
    letterSpacing: '$narrowS',
    fontSize: '$L',
    fontWeight: 900,
    lineHeight: '3.6rem',
  },
  headingM: {
    letterSpacing: '$normal',
    fontSize: '$M',
    fontWeight: 900,
    lineHeight: '3.2rem',
  },
};
